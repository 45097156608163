import React from "react";
import {MiniTable} from "./tableSearch";
import {StyleCSSHeadGrid} from "../common/styleGrid";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import {tListEvent} from "../../commons/commons";
import {ApiClient} from "../reactI";
import {iPositionLimitGet, iPositionMarketGet} from "../../sistem/base/Position";
import moment from "moment";
import {tOnTicksRec} from "../../sistem/base/priceReceiver";
import {Navigation} from "./Navigation";
import {RowNode} from "ag-grid-community";
type tOrdersType = "buy"|"sell"|"buyLimit"|"sellLimit"
type tRowData = {}
type tRow = {
    orderId: number|any,
    openTime?: Date|undefined|any,
    price?: number|any,
    comission?: any|undefined,
    type: tOrdersType|any,
    volume: number|any,
    symbol:string|any,
    num: number|any,
    profit: any|undefined,
    dividentValue: any|undefined,
    portfolioDivident: any|undefined,
    portfolioValue: any|undefined,
    livePrice?:number|any
}
type tRowKey = keyof tRow

const OrdersNow: tRow[] = []
declare const window: any;

export class TableOrdersNow extends MiniTable<tRow,{}>{

    componentDidMount() {
        this.Init()
        this.profitUpdate()
        OrdersNow.forEach((e,i)=>{
            const pos = OrdersNow[i]
            const buf = {
                func: () => ({
                    onTicks:(datum: tOnTicksRec)=>{
                        // pos.profit = (((pos.type == "buy" ? datum.bid : (pos.price ?? 0)) - (pos.type == "buy" ? (pos.price ?? 0) : datum.bid))*pos.volume-(pos.comission ?? 0)).toFixed(2);
                        // this.gridApi?.applyTransactionAsync({update: [OrdersNow[i]]})
                    }
                })
            }
            this.arrTicksSymbols.push(buf)
            ApiClient.setEventTicksBySymbol({callback: buf, symbol: e.symbol})
        })
    }

    componentWillUnmount() {
        this.callback.del?.();
        this.arrTicksSymbols.forEach(e=>e?.del?.())
        this.arrTicksSymbols.splice(0)
        this.gridApi = null
    }

    callback:  tListEvent = {
        func:(data:  any ) =>{
            this.update(data)
        }
    }

    callbackClose:  tListEvent = {
        func:(data:  iPositionLimitGet ) =>{
            this.update(data)
        }
    }

    profitUpdate(){
        console.log("!!!!!")
        this.arrTicksSymbols.forEach(e=>e?.del?.())
        this.arrTicksSymbols.splice(0)
        OrdersNow.forEach((e,i)=>{
            if (e.type=="buy" || e.type=="sell" ) {
                const pos = OrdersNow[i]
                const buf = {
                        func: () => ({
                            onTicks:(datum: tOnTicksRec)=>{
                                if (!OrdersNow.length) {
                                    return false;
                                }
                                // pos.profit = (((pos.type == "buy" ? datum.bid : (pos.price ?? 0)) - (pos.type == "buy" ? (pos.price ?? 0) : datum.bid))*pos.volume-(pos.comission ?? 0)).toFixed(2);
                                let entryPrice = +(pos.price ?? 0);
                                let livePrice = datum.bid;
                                let diffPrice;

                                if (window.symbols && window.symbols.indexOf(pos.symbol) > 9) {
                                    entryPrice = Math.abs(entryPrice) * -1;
                                    livePrice = Math.abs(livePrice) * -1;
                                }

                                if (pos.type == "buy") {
                                    diffPrice = livePrice - entryPrice;
                                } else {
                                    diffPrice = entryPrice - livePrice;
                                }

                                pos.profit = (diffPrice * Math.abs(pos.volume)-(pos.comission ?? 0)).toFixed(2);


                                // pos.livePrice = datum.bid.toFixed(2);
                                pos.livePrice = (window.symbols && window.symbols.indexOf(pos.symbol) > 9 ? (datum.bid??0)*-1 : (datum.bid??0)).toFixed(2);
                                const index = window.symbols.indexOf(pos.symbol);
                                const symbolIndexWithDivident = [0, 1, 2, window.symbols.length-1, window.symbols.length-2, window.symbols.length-3];
                                pos.portfolioValue = symbolIndexWithDivident.indexOf(index) > -1 ? ((+((+pos.dividentValue).toFixed(2)) + +((+pos.profit).toFixed(2)))).toFixed(2) : '';
                                // pos.equity = ((pos.balance??0) + +pos.profit).toFixed(2);
                                // this.gridApi?.de

                                this.gridApi?.applyTransactionAsync({update: [OrdersNow[i]]})

                                const totalRow = OrdersNow[OrdersNow.length-2];

                                const profit = OrdersNow.slice(0, OrdersNow.length-2).reduce(function(sum, current) {
                                        return +(sum + +current.profit);
                                }, 0);

                                const dividentValue = OrdersNow.slice(0, OrdersNow.length-2).reduce(function(sum, current) {
                                    return +(sum + +current.dividentValue);
                                }, 0);

                                const portfolioValue = OrdersNow.slice(0, OrdersNow.length-2).reduce(function(sum, current) {
                                    return +(sum + +current.portfolioValue);
                                }, 0);

                                totalRow.profit = profit.toFixed(2);
                                totalRow.dividentValue = dividentValue.toFixed(2);
                                totalRow.portfolioValue = portfolioValue.toFixed(2);

                                const balanceRow = OrdersNow[OrdersNow.length-1];
                                const balanceValue = +balanceRow.orderId.split(' ')[1];
                                // const equity = balanceValue + profit;
                                // ApiClient.profit = equity;
                                window.equity = window.balance + profit;
                                balanceRow.openTime = 'Equity ' + window.equity.toFixed(2);

                                this.gridApi?.applyTransactionAsync({update: [OrdersNow[OrdersNow.length-2]]})
                                this.gridApi?.applyTransactionAsync({update: [OrdersNow[OrdersNow.length-1]]})
                                window.OrdersNow = OrdersNow;

                            }
                        })
                    }
                this.arrTicksSymbols.push(buf)
                ApiClient.setEventTicksBySymbol({callback: buf, symbol: e.symbol})
            }
        })
        this.arrTicksSymbols.forEach((e)=>{
        })
    }

    async update(data: any){
        const result =  await ApiClient.getOrdersAll({})
        const balance = await ApiClient.getOrdersHistory({}).then(e=> {
            const profit = e.slice(0, e.length).reduce(function(sum, current) {
                return +(sum + +current.profit);
            }, 0);
            return profit + 100000;
        })

        OrdersNow.splice(0);

        if (result.length) {
            const volume = result.reduce(function(sum, current) {
                return sum + current.volume;
            }, 0);
            const comission = result.reduce(function(sum, current) {
                return sum + (current.price??0)*0.01*Math.abs(current.volume);
            }, 0);

            const profit = result.reduce(function(sum, current) {
                return sum + +((current.price??0)*0.01*current.volume).toFixed(2);
            }, 0);

            window.equity = window.balance + profit;
            // const equity = 100000+ +this.getTotalProfitClosed();
            // window.equity = equity-comission;
            // window.balance = balance;

            const totalData:tRow = {
                symbol: '',
                price: '',
                comission: comission.toFixed(2),
                livePrice: '',
                openTime: '',
                volume: volume,
                type: '',
                orderId: 'Total',
                num: 17,
                profit: 0, // надо будет дописать когда будет история
                portfolioDivident: '',
                dividentValue: 0,
                portfolioValue: 0
            }

            const balanceDataLbl:tRow = {
                symbol: '',
                price: '',
                comission: '',
                livePrice: '',
                openTime: 'Equity ' + window.equity.toFixed(2),
                volume: '',
                type: '',
                orderId: 'Balance ' + window.balance.toFixed(2),
                num: '',
                profit: '',
                portfolioDivident: '',
                dividentValue: '',
                portfolioValue: ''
            }



            OrdersNow.push(...this.toRow(result))
            OrdersNow.push(totalData);
            OrdersNow.push(balanceDataLbl);
        } else {
            const balanceDataLbl:tRow = {
                symbol: '',
                price: '',
                comission: '',
                livePrice: '',
                openTime: 'Equity ' + window.balance.toFixed(2),
                volume: '',
                type: '',
                orderId: 'Balance ' + window.balance.toFixed(2),
                num: '',
                profit: '',
                portfolioDivident: '',
                dividentValue: '',
                portfolioValue: ''
            }
            OrdersNow.push(...this.toRow(result))
            OrdersNow.push(balanceDataLbl);
        }

        this.profitUpdate()

        this.gridApi?.setRowData(OrdersNow)

        setTimeout(()=> {
            this.gridApi?.sizeColumnsToFit()
        }, 100)

    }
    arrTicksSymbols: tListEvent[] = []
    toRow(data: (iPositionMarketGet | iPositionLimitGet)[]): tRow[]{
        return data.map((e,i)=>{
            return {
                symbol: e.symbol,
                price: (window.symbols && window.symbols.indexOf(e.symbol) > 9 ? (e.price??0)*-1 : (e.price??0)).toFixed(2),
                comission: this.getComission((window.symbols && window.symbols.indexOf(e.symbol) > 9 ? (e.price??0)*-1 : (e.price??0)), e.volume),
                livePrice: (window.symbols && window.symbols.indexOf(e.symbol) > 9 ? (e.price??0)*-1 : (e.price??0)).toFixed(2),
                openTime: e.timeLimit ? e.timeLimit : e.timeOpen,
                volume: e.volume,
                type: e.type=="Limit"? e.volume>0? "buyLimit" : "sellLimit" : e.volume>0? "buy" : "sell",
                orderId: e.orderId,
                num: i,
                profit: 0, // надо будет дописать когда будет история
                portfolioDivident: this.getPortfolioDivident(e.symbol, (window.symbols && window.symbols.indexOf(e.symbol) > 9 ? (e.price??0)*-1 : (e.price??0))),
                dividentValue: this.getDividentValue(e.symbol, (window.symbols && window.symbols.indexOf(e.symbol) > 9 ? (e.price??0)*-1 : (e.price??0)), e.volume),
                portfolioValue: this.getDividentValue(e.symbol, (window.symbols && window.symbols.indexOf(e.symbol) > 9 ? (e.price??0)*-1 : (e.price??0)), e.volume)
            }
        })
    }

    getComission(price:number, volume:number){
        return (Math.abs(price??0)*0.01*Math.abs(volume)).toFixed(2);
    }

    getPortfolioDivident(symbol:string, price:number) {
        const index = window.symbols.indexOf(symbol);

        if (index == 0 || index == window.symbols.length-1) {
            return (.6 * Math.abs(price)).toFixed(2);
        }

        if (index == 1 || index == window.symbols.length-2) {
            return (.3 * Math.abs(price)).toFixed(2);
        }

        if (index == 2 || index == window.symbols.length-3) {
            return (.1 * Math.abs(price)).toFixed(2);
        }

        return '';
    }

    getDividentValue(symbol:string, price:number, volume:number) {
        const index = window.symbols.indexOf(symbol);

        if (index == 0 || index == window.symbols.length-1) {
            return (.6 * Math.abs(price) * Math.abs(volume)).toFixed(2);
        }

        if (index == 1 || index == window.symbols.length-2) {
            return (.3 * Math.abs(price) * Math.abs(volume)).toFixed(2);
        }

        if (index == 2 || index == window.symbols.length-3) {
            return (.1 * Math.abs(price) * Math.abs(volume)).toFixed(2);
        }

        return '';
    }

    Init(){
        ApiClient.setEventMarket({callback: this.callback})
        ApiClient.setEventLimit({callback: this.callback})
        ApiClient.setEventLimit({callback: this.callback})
        ApiClient.setEventClose({callback: this.callback})
        // ApiClient.setEventLimit({callback: this.callbackLimit})
        // ApiClient.setEventLimit({callback: this.callbackM})
        // ApiClient.setEventLimit({callback: this.callbackM})
        ApiClient.getOrdersAll({}).then(e=>{
            if (!this.gridApi) {
                this.update([]);
            } else {
                this.gridApi?.setRowData(this.toRow(e))
            }
        })
        ApiClient.getOrdersHistory({}).then(e=> {
            console.log('history:', e);
        })
        // this.gridApi?.applyTransactionAsync()
    }

    async getTotalProfitClosed() {
        ApiClient.getOrdersHistory({}).then(e=> {
            const profit = e.slice(0, e.length).reduce(function(sum, current) {
                return +(sum + +current.profit);
            }, 0);
            console.log('getTotalProfitClosed:', profit + 100000);
            return profit + 100000;

        })
    }

    searchDiv({table, search}: {table: JSX.Element, search?: string }) {
        return <div style={{ width: "100%", height:"100%"}}>
                {table}
        </div>
    }

    table(){
        StyleCSSHeadGrid();
        this.gridOptions.suppressMovableColumns = true;
        // const pinnedRows = [];
        this.gridOptions.postSortRows = (params) => {
            let rowNodes = params.nodes;
            let totalItem;
            for(let x in rowNodes) {
                if (rowNodes[x].data.orderId && rowNodes[x].data.orderId.toString().indexOf('Total') != -1 ) {
                    totalItem = rowNodes[x];
                    rowNodes.splice(+x,1);
                    rowNodes.splice(rowNodes.length, 1, totalItem)
                }

                if (rowNodes[x].data.orderId && rowNodes[x].data.orderId.toString().indexOf('Balance') != -1 ) {
                    totalItem = rowNodes[x];
                    rowNodes.splice(+x,1);
                    rowNodes.splice(rowNodes.length, 1, totalItem)
                }

            }
        }
        return <><AgGridReact
            onGridReady={(e) => {
                this.gridApi = e.api;
            }}
            className="ag-theme-alpine"
            onGridSizeChanged={() => {
                this.gridApi?.sizeColumnsToFit();
            }}
            rowData={OrdersNow}
            headerHeight={60}
            rowHeight={28}
            rowSelection={this.props.getSelectedRows ? 'multiple' : 'single'}
            gridOptions={this.props.gridOptions ?? this.gridOptions}
            getRowId={({data}) => this.props.getRiwId?.(data) ?? data.num}
        >
            <AgGridColumn
                field={"orderId" as tRowKey}
                headerName={"Order"}
                headerClass={"gridTable-header"}
                sortable={true}
                filter={false}
                resizable={false}
                // cellStyle={this.styleOrdersTable}
                cellStyle={(param:any) => {
                    if (typeof param.value == 'string' && param.value.indexOf('Total') > -1) {
                        return this.styleOrdersLastRow
                    }

                    if (typeof param.value == 'string' && param.value.indexOf('Balance') > -1) {
                        return this.styleOrdersBalanceRow
                    }

                    // if (param.rowIndex == OrdersNow.length-1 || param.rowIndex == OrdersNow.length-2) {
                    //     return this.styleOrdersLastRow
                    // }
                    return this.styleOrdersTable
                }}
                autoHeight={true}

            />

            <AgGridColumn
                field={"openTime" as tRowKey}
                headerName={"Open Time"}
                headerClass={"gridTable-header"}
                sortable={true}
                filter={true}
                resizable={false}
                cellStyle={(param:any) => {
                    if (typeof param.value == 'string' && param.value.indexOf('Equity') > -1) {
                        return this.styleOrdersBalanceRow
                    }
                    return this.styleOrdersTable
                }}
                valueFormatter={(data: any) => {
                    if (typeof data.value == 'string') {
                        return data.value;
                    } else {
                        return moment(data.value).format('DD-MM-YYYY HH:mm:ss');
                    }

                }}
                autoHeight={true}


            />

            <AgGridColumn
                field={"symbol" as tRowKey}
                headerName={"Team"}
                headerClass={"gridTable-header"}
                sortable={true}
                filter={true}
                resizable={false}
                cellStyle={(param:any) => {
                    // if (param.rowIndex == OrdersNow.length-1 || param.rowIndex == OrdersNow.length-2) {
                    //     return this.styleOrdersLastRow
                    // }
                    return this.styleOrdersTable
                }}
                cellClass={"symbol"}
                autoHeight={true}

            />

            <AgGridColumn
                field={"type" as tRowKey}
                headerName={"Direction"}
                headerClass={"gridTable-header"}
                sortable={true}
                filter={true}
                resizable={false}
                cellStyle={(param:any) => {
                    // if (param.rowIndex == OrdersNow.length-1 || param.rowIndex == OrdersNow.length-2) {
                    //     return this.styleOrdersLastRow
                    // }
                    return this.styleOrdersTable
                }}
                cellClass={(params: any) => {
                    return params.value
                }}
                valueFormatter={(data: any) => {
                    if (data.value=='') {
                        return ''
                    } else {
                        return data.value && (data.value.toUpperCase() == 'BUY' || data.value.toUpperCase() == 'BUYLIMIT') ? 'Long' : 'Short';
                    }
                }}
                autoHeight={true}

            />

            <AgGridColumn
                field={"volume" as tRowKey}
                headerName={"Shares"}
                headerClass={"gridTable-header"}
                sortable={true}
                filter={true}
                resizable={false}
                cellStyle={(param:any) => {
                    if (typeof param.data.orderId == 'string' && param.data.orderId.indexOf('Total') > -1) {
                        return this.styleOrdersLastRow
                    }
                    // if (param.rowIndex == OrdersNow.length-1 || param.rowIndex == OrdersNow.length-2) {
                    //     return this.styleOrdersLastRow
                    // }
                    return this.styleOrdersTable
                }}
                autoHeight={true}
                // valueFormatter={(data: any) => {
                //     console.log('index:', data.node.rowIndex);
                //     if (data.node.rowIndex == OrdersNow.length-2) {
                //         const result = OrdersNow.reduce(function(sum, current) {
                //             return sum + current.volume;
                //         }, 0);
                //        return result;
                //     } else {
                //         return data.value
                //     }
                // }}

            />
            <AgGridColumn
                field={"price" as tRowKey}
                headerName={"Price"}
                headerClass={"gridTable-header"}
                sortable={true}
                filter={true}
                resizable={false}
                cellStyle={(param:any) => {
                    // if (param.rowIndex == OrdersNow.length-1 || param.rowIndex == OrdersNow.length-2) {
                    //     return this.styleOrdersLastRow
                    // }
                    return this.styleOrdersTable
                }}
                autoHeight={true}
            />

            <AgGridColumn
                field={"comission" as tRowKey}
                headerName={"Commission"}
                headerClass={"gridTable-header"}
                sortable={true}
                filter={true}
                resizable={false}
                cellStyle={(param:any) => {
                    if (typeof param.data.orderId == 'string' && param.data.orderId.indexOf('Total') > -1) {
                        return this.styleOrdersLastRow
                    }
                    // if (param.rowIndex == OrdersNow.length-1 || param.rowIndex == OrdersNow.length-2) {
                    //     return this.styleOrdersLastRow
                    // }
                    return this.styleOrdersTable
                }}
                autoHeight={true}

            />

            <AgGridColumn
                field={"livePrice" as tRowKey}
                headerName={"Live Price"}
                headerClass={"gridTable-header"}
                sortable={true}
                filter={true}
                resizable={false}
                cellStyle={(param:any) => {
                    // if (param.rowIndex == OrdersNow.length-1 || param.rowIndex == OrdersNow.length-2) {
                    //     return this.styleOrdersLastRow
                    // }
                    return this.styleOrdersTable
                }}
                autoHeight={true}

            />

            <AgGridColumn
                field={"profit" as tRowKey}
                headerName={"Profit/Loss"}
                headerClass={"gridTable-header"}
                sortable={true}
                filter={true}
                resizable={false}
                cellStyle={(param:any) => {
                    if (typeof param.data.orderId == 'string' && param.data.orderId.indexOf('Total') > -1) {
                        return this.styleOrdersLastRow
                    }
                    // if (param.rowIndex == OrdersNow.length-1 || param.rowIndex == OrdersNow.length-2) {
                    //     return this.styleOrdersLastRow
                    // }
                    return this.styleOrdersTable
                }}
                autoHeight={true}

            />

            <AgGridColumn
                field={"portfolioDivident" as tRowKey}
                headerName={"Portfolio Dividend per Share"}
                headerClass={"gridTable-header"}
                sortable={true}
                filter={true}
                resizable={false}
                cellStyle={(param:any) => {
                    // if (param.rowIndex == OrdersNow.length-1 || param.rowIndex == OrdersNow.length-2) {
                    //     return this.styleOrdersLastRow
                    // }
                    return this.styleOrdersTable
                }}
                autoHeight={true}

            />

            <AgGridColumn
                field={"dividentValue" as tRowKey}
                headerName={"Dividend Value"}
                headerClass={"gridTable-header"}
                sortable={true}
                filter={true}
                resizable={false}
                cellStyle={(param:any) => {
                    if (typeof param.data.orderId == 'string' && param.data.orderId.indexOf('Total') > -1) {
                        return this.styleOrdersLastRow
                    }
                    // if (param.rowIndex == OrdersNow.length-1 || param.rowIndex == OrdersNow.length-2) {
                    //     return this.styleOrdersLastRow
                    // }
                    return this.styleOrdersTable
                }}
                autoHeight={true}

            />

            <AgGridColumn
                field={"portfolioValue" as tRowKey}
                headerName={"Portfolio Value"}
                headerClass={"gridTable-header"}
                sortable={true}
                filter={true}
                resizable={false}
                cellStyle={(param:any) => {
                    if (typeof param.data.orderId == 'string' && param.data.orderId.indexOf('Total') > -1) {
                        return this.styleOrdersLastRow
                    }
                    // if (param.rowIndex == OrdersNow.length-1 || param.rowIndex == OrdersNow.length-2) {
                    //     return this.styleOrdersLastRow
                    // }
                    return this.styleOrdersTable
                }}
                autoHeight={true}

            />

            <AgGridColumn
                field={"num" as tRowKey}
                headerName={"delete"}
                headerClass={"gridTable-header"}
                sortable={true}
                filter={true}
                cellClass={"delete"}
                valueGetter={(data:any) => {
                    if (data.data.type == '') {
                        return '';
                    }

                    return "✕";
                }}
                // resizable={true}
                onCellClicked={({data}: { data: tRow }) => {
                    ApiClient.closeOrders({ordersId: [data.orderId]}).then(() => this.update({}));
                    this.gridApi?.refreshCells();
                }}
                cellStyle={(param:any) => {
                    // if (param.rowIndex == OrdersNow.length-1 || param.rowIndex == OrdersNow.length-2) {
                    //     return this.styleOrdersLastRow
                    // }
                    return this.styleOrdersTable
                }}
                autoHeight={true}

            />

        </AgGridReact>
        <Navigation />
        </>
    }

}
