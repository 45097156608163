import React from "react";
import moment from 'moment';
import {MiniTable} from "./tableSearch";
import {StyleCSSHeadGrid} from "../common/styleGrid";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import {ApiClient} from "../reactI";
import {tListEvent} from "../../commons/commons";
import {iPositionLimitGet, iPositionMarketGet, iPositionMarketGetHistory} from "../../sistem/base/Position";
import {Navigation} from "./Navigation";
import {tOnTicksRec} from "../../sistem/base/priceReceiver";

type tOrdersType = "buy"|"sell"|"buyLimit"|"sellLimit"
type tRowData = {}
type tRow = {
    orderId: number|any,
    openTime: Date|any,
    openPrice: number|any,
    closeTime: Date|any,
    closePrice: number|any,
    type: tOrdersType|any,
    volume: number|any,
    symbol:string|any,
    num: number|any,
    profit:any|undefined,
    dividentValue: any|undefined,
    portfolioDivident: any|undefined,
    portfolioValue: any|undefined,
    comission?: any|undefined
}
type tRowKey = keyof tRow
declare const window: any;

// const OrdersHistory: tRow[] = []
const OrdersHistory: tRow[] = []

export class TableOrderHistory extends MiniTable<tRow,{}>{
    componentDidMount() {
        this.Init()
    }

    componentWillUnmount() {
        this.callbackClose.del?.();
    }

    callbackClose:  tListEvent = {
        func:(data: any) =>{
            this.update(data)
        }
    }
    async update(data: any){
        const result =  await ApiClient.getOrdersHistory({})
        OrdersHistory.splice(0)
        if (result.length) {
            const volume = result.reduce(function(sum, current) {
                return sum + current.volume;
            }, 0);
            const comission = result.reduce(function(sum, current) {
                return sum + +((current.price??0)*0.01*current.volume).toFixed(2);
            }, 0);
            const profit = result.reduce(function(sum, current) {
                return +(sum + +current.profit);
            }, 0);

            const totalData:tRow = {
                symbol: '',
                openTime: '',
                openPrice: '',
                closePrice: '',
                closeTime: '',
                volume: volume,
                type: '',
                orderId: 'Total',
                num: '',
                profit: 0,
                comission: comission.toFixed(2),
                portfolioDivident: '',
                dividentValue: 0,
                portfolioValue: 0
            }

            const balanceDataLbl:tRow = {
                symbol: '',
                openTime: 'Equity 100000',
                openPrice: '',
                closePrice: '',
                closeTime: '',
                volume: '',
                type: '',
                orderId: 'Balance ' + (+profit + 100000),
                num: '',
                profit: '',
                comission: '',
                portfolioDivident: '',
                dividentValue: '',
                portfolioValue: ''
            }

            OrdersHistory.push(...this.toRow(result))
            OrdersHistory.push(totalData);
            OrdersHistory.push(balanceDataLbl);
        } else {
            OrdersHistory.push(...this.toRow(result))
        }
        this.gridApi?.setRowData(OrdersHistory)

        setTimeout(()=> {
            this.gridApi?.sizeColumnsToFit()
        }, 100)
    }
    Init(){
        ApiClient.setEventClose({callback: this.callbackClose})
        // if (window.OrdersNow) {
        //     window.OrdersNow.forEach((e:any,i:number)=>{
        //         const pos = window.OrdersNow[i]
        //         const buf = {
        //             func: () => ({
        //                 onTicks:(datum: tOnTicksRec)=>{
        //                     pos.profit = (((pos.type == "buy" ? datum.bid : (pos.price ?? 0)) - (pos.type == "buy" ? (pos.price ?? 0) : datum.bid))*pos.volume-(pos.comission ?? 0)).toFixed(2);
        //                     window.OrdersNow[i] = pos;
        //                     const profit = window.OrdersNow.slice(0, window.OrdersNow.length-2).reduce(function(sum:any, current:any) {
        //                         return +(sum + +current.profit);
        //                     }, 0);
        //                     // window.OrdersHistory[window.OrdersHistory.length-1]
        //                     let lastrow = this.gridApi?.getDisplayedRowAtIndex(this.gridApi.getLastDisplayedRow());
        //                     if (lastrow) {
        //                         if (lastrow?.data) {
        //                             const balanceValue = +lastrow.data.orderId.split('Balance')[1];
        //                             lastrow.data.openTime = 'Equity ' + (balanceValue+profit).toFixed(2);
        //                         }
        //                         this.gridApi?.applyTransactionAsync({update: [lastrow?.data]})
        //
        //                         // this.gridApi?.updateRowData({ update: [lastrow?.data] });
        //                     }
        //
        //                 }
        //             })
        //         }
        //         ApiClient.setEventTicksBySymbol({callback: buf, symbol: e.symbol})
        //     })
        // }
    }

    toRow(data: (iPositionMarketGetHistory )[]): tRow[]{
        console.log('data:', data.map((e,i)=>({
            symbol: e.symbol,
            openTime: e.timeOpen,
            openPrice: e.price??0,
            closePrice: e.priceClose??0,
            closeTime: e.timeClosed??0,
            volume: e.volume,
            type: e.volume>0? "buy" : "sell",
            orderId: e.orderId,
            num: i,
            profit: e.profit.toFixed(2),
            comission: ((e.priceClose??0)*0.01*e.volume).toFixed(2),
            portfolioDivident: this.getPortfolioDivident(e.symbol, e.priceClose??0),
            dividentValue: this.getDividentValue(e.symbol, e.priceClose??0, e.volume),
            portfolioValue: this.getPortfolioValue(e.symbol, e.priceClose??0, e.volume, e.profit)
        })));

        const result = data.map((e,i)=>({
            symbol: e.symbol,
            openTime: e.timeOpen,
            openPrice: (window.symbols && window.symbols.indexOf(e.symbol) > 9 ? (e.price??0)*-1 : (e.price??0)).toFixed(2),
            closePrice: (window.symbols && window.symbols.indexOf(e.symbol) > 9 ? (e.priceClose??0)*-1 : (e.priceClose??0)).toFixed(2),
            closeTime: e.timeClosed??0,
            volume: e.volume,
            type: e.volume>0? "buy" : "sell",
            orderId: e.orderId,
            num: i,
            profit: e.profit.toFixed(2),
            comission: ((e.priceClose??0)*0.01*Math.abs(e.volume)).toFixed(2),
            portfolioDivident: this.getPortfolioDivident(e.symbol, (window.symbols && window.symbols.indexOf(e.symbol) > 9 ? (e.priceClose??0)*-1 : (e.priceClose??0))),
            dividentValue: this.getDividentValue(e.symbol, (window.symbols && window.symbols.indexOf(e.symbol) > 9 ? (e.priceClose??0)*-1 : (e.priceClose??0)), e.volume),
            portfolioValue: this.getPortfolioValue(e.symbol, (window.symbols && window.symbols.indexOf(e.symbol) > 9 ? (e.priceClose??0)*-1 : (e.priceClose??0)), e.volume, e.profit)
        }));

        if (!result.length) {
            return result;
        }

        const volume = result.reduce(function(sum, current) {
            return sum + +current.volume;
        }, 0);

        const comission = result.reduce(function(sum, current) {
            return sum + +current.comission;
        }, 0);

        const profit = result.slice(0, result.length).reduce(function(sum, current) {
            return +(sum + +current.profit);
        }, 0);
        const dividentValue = result.slice(0, result.length).reduce(function(sum, current) {
            return +(sum + +current.dividentValue);
        }, 0);

        const portfolioValue = result.slice(0, result.length).reduce(function(sum, current) {
            return +(sum + +current.portfolioValue);
        }, 0);

        const totalData:any = {
            symbol: '',
            openTime: '',
            openPrice: '',
            closePrice: '',
            closeTime: '',
            volume: volume,
            type: '',
            orderId: 'Total',
            num: '',
            profit: profit,
            comission: comission.toFixed(2),
            portfolioDivident: '',
            dividentValue: dividentValue.toFixed(2),
            portfolioValue: portfolioValue.toFixed(2)
        }
        result.push(totalData);
        window.OrdersHistory = result;

        return result;
    }

    getPortfolioDivident(symbol:string, price:number) {
        const index = window.symbols.indexOf(symbol);

        if (index == 0 || index == window.symbols.length-1) {
            return (.6 * Math.abs(price)).toFixed(2);
        }

        if (index == 1 || index == window.symbols.length-2) {
            return (.3 * Math.abs(price)).toFixed(2);
        }

        if (index == 2 || index == window.symbols.length-3) {
            return (.1 * Math.abs(price)).toFixed(2);
        }

        return '';
    }

    getDividentValue(symbol:string, price:number, volume:number) {
        const index = window.symbols.indexOf(symbol);

        if (index == 0 || index == window.symbols.length-1) {
            return (.6 * Math.abs(price) * Math.abs(volume)).toFixed(2);
        }

        if (index == 1 || index == window.symbols.length-2) {
            return (.3 * Math.abs(price) * Math.abs(volume)).toFixed(2);
        }

        if (index == 2 || index == window.symbols.length-3) {
            return (.1 * Math.abs(price) * Math.abs(volume)).toFixed(2);
        }

        return '';
    }

    getPortfolioValue(symbol:string, price:number, volume:number, profit:number) {
        const index = window.symbols.indexOf(symbol);
        const symbolIndexWithDivident = [0, 1, 2, window.symbols.length-1, window.symbols.length-2, window.symbols.length-3];

        if (symbolIndexWithDivident.indexOf(index) > -1) {
            return ((+((+this.getDividentValue(symbol, price, volume)).toFixed(2)) +
                +((+profit).toFixed(2)))).toFixed(2)
        } else {
            return '';
        }
    }

    searchDiv({table, search}: {table: JSX.Element, search?: string }) {
        return <div style={{ width: "100%", height:"100%"}}>
            {table}
        </div>
    }
    table(){
        StyleCSSHeadGrid();
        this.gridOptions.suppressMovableColumns = true;
        this.gridOptions.postSortRows = (params) => {
            let rowNodes = params.nodes;
            let totalItem;
            for(let x in rowNodes) {
                if (rowNodes[x].data.orderId && rowNodes[x].data.orderId.toString().indexOf('Total') != -1 ) {
                    totalItem = rowNodes[x];
                    rowNodes.splice(+x,1);
                    rowNodes.splice(rowNodes.length, 1, totalItem);
                    this.gridApi?.redrawRows();
                    this.gridApi?.sizeColumnsToFit();
                }

                if (rowNodes[x].data.orderId && rowNodes[x].data.orderId.toString().indexOf('Balance') != -1 ) {
                    totalItem = rowNodes[x];
                    rowNodes.splice(+x,1);
                    rowNodes.splice(rowNodes.length, 1, totalItem);
                    this.gridApi?.redrawRows();
                    this.gridApi?.sizeColumnsToFit();
                }
            }

            setTimeout(() => {
                this.gridApi?.redrawRows();
                this.gridApi?.sizeColumnsToFit();
            }, 100);


        }
        return <><AgGridReact
            onGridReady={(e)=> {
                this.gridApi = e.api
                ApiClient.getOrdersHistory({}).then(e=> {
                    // OrdersHistory.push(...this.toRow(e));
                    this.gridApi?.setRowData(this.toRow(e))
                })

            }}
            className= "ag-theme-alpine"
            onGridSizeChanged={()=>{
                this.gridApi?.sizeColumnsToFit();
            }}
            rowData = {OrdersHistory}
            headerHeight = {60}
            rowHeight = {28}
            rowSelection ={this.props.getSelectedRows?'multiple':'single'}

            gridOptions = {this.props.gridOptions ?? this.gridOptions}
            getRowId = {  ({data}) =>this.props.getRiwId?.(data) ?? data[this.props.searchBy ?? "symbol"] }
        >
            <AgGridColumn
                field={"orderId" as tRowKey}
                headerName= {"Order"}
                headerClass= {"gridTable-header"}
                sortable= {true}
                filter= {true}
                resizable={false}
                cellStyle={(param:any) => {
                    if (param.data.type == '') {
                        return this.styleOrdersHistoryLastRow
                    }
                    return this.styleOrdersHistoryTable
                }}

            />

            <AgGridColumn
                field={"openTime" as tRowKey}
                headerName= {"Open Time"}
                headerClass= {"gridTable-header"}
                sortable= {true}
                filter= {true}
                resizable={false}
                cellStyle={(param:any) => {
                    if (param.data.type == '') {
                        return this.styleOrdersHistoryLastRow
                    }
                    return this.styleOrdersHistoryTable
                }}

                valueFormatter={(data: any) => {
                    if (typeof data.value == 'string') {
                        return data.value;
                    } else {
                        return moment(data.value).format('DD-MM-YYYY HH:mm:ss');
                    }

                }}

            />

            <AgGridColumn
                field={"closeTime" as tRowKey}
                headerName= {"Close Time"}
                headerClass= {"gridTable-header"}
                sortable= {true}
                filter= {true}
                resizable={false}
                cellStyle={(param:any) => {
                    if (param.data.type == '') {
                        return this.styleOrdersHistoryLastRow
                    }
                    return this.styleOrdersHistoryTable
                }}
                valueFormatter={(data: any) => {
                    if (typeof data.value == 'string') {
                        return data.value;
                    } else {
                        return moment(data.value).format('DD-MM-YYYY HH:mm:ss');
                    }

                }}

            />

            <AgGridColumn
                field={"symbol" as tRowKey}
                headerName= {"Team"}
                headerClass= {"gridTable-header"}
                sortable= {true}
                filter= {true}
                resizable={false}
                cellStyle={(param:any) => {
                    if (param.data.type == '') {
                        return this.styleOrdersHistoryLastRow
                    }
                    return this.styleOrdersHistoryTable
                }}
                cellClass={"symbol"}

            />

            <AgGridColumn
                field={"type" as tRowKey}
                headerName= {"Direction"}
                headerClass= {"gridTable-header"}
                sortable= {true}
                filter= {true}
                resizable={false}
                cellStyle={(param:any) => {
                    if (param.data.type == '') {
                        return this.styleOrdersHistoryLastRow
                    }
                    return this.styleOrdersHistoryTable
                }}
                cellClass={(params: any) => {
                    return params.value
                }}
                valueFormatter={(data: any) => {
                    if (typeof data.value == 'string') {
                        return data.value;
                    } else {
                        return data.value && (data.value.toUpperCase() == 'BUY' || data.value.toUpperCase() == 'BUYLIMIT') ? 'Long' : 'Short';
                    }
                }}

            />

            <AgGridColumn
                field={"volume" as tRowKey}
                headerName= {"Shares"}
                headerClass= {"gridTable-header"}
                sortable= {true}
                filter= {true}
                resizable={false}
                cellStyle={(param:any) => {
                    if (param.data.type == '') {
                        return this.styleOrdersHistoryLastRow
                    }
                    return this.styleOrdersHistoryTable
                }}

            />
            <AgGridColumn
                field={"openPrice" as tRowKey}
                headerName= {"Open Price"}
                headerClass= {"gridTable-header"}
                sortable= {true}
                filter= {true}
                resizable={false}
                cellStyle={(param:any) => {
                    if (param.data.type == '') {
                        return this.styleOrdersHistoryLastRow
                    }
                    return this.styleOrdersHistoryTable
                }}

            />
            <AgGridColumn
                field={"comission" as tRowKey}
                headerName={"Commission"}
                headerClass={"gridTable-header"}
                sortable={true}
                filter={true}
                resizable={false}
                cellStyle={(param:any) => {
                    if (param.data.type == '') {
                        return this.styleOrdersHistoryLastRow
                    }
                    return this.styleOrdersHistoryTable
                }}
            />

            <AgGridColumn
                field={"closePrice" as tRowKey}
                headerName= {"Close price"}
                headerClass= {"gridTable-header"}
                sortable= {true}
                filter= {true}
                resizable={false}
                cellStyle={(param:any) => {
                    if (param.data.type == '') {
                        return this.styleOrdersHistoryLastRow
                    }
                    return this.styleOrdersHistoryTable
                }}

            />


            <AgGridColumn
                field={"profit" as tRowKey}
                headerName= {"Profit/Loss"}
                headerClass= {"gridTable-header"}
                sortable= {true}
                filter= {true}
                resizable={false}
                cellStyle={(param:any) => {
                    if (param.data.type == '') {
                        return this.styleOrdersHistoryLastRow
                    }
                    return this.styleOrdersHistoryTable
                }}

            />

            <AgGridColumn
                field={"portfolioDivident" as tRowKey}
                headerName={"Portfolio Dividend per Share"}
                headerClass={"gridTable-header"}
                sortable={true}
                filter={true}
                resizable={false}
                cellStyle={(param:any) => {
                    if (param.data.type == '') {
                        return this.styleOrdersHistoryLastRow
                    }
                    return this.styleOrdersHistoryTable
                }}

            />

            <AgGridColumn
                field={"dividentValue" as tRowKey}
                headerName={"Dividend Value"}
                headerClass={"gridTable-header"}
                sortable={true}
                filter={true}
                resizable={false}
                cellStyle={(param:any) => {
                    if (param.data.type == '') {
                        return this.styleOrdersHistoryLastRow
                    }
                    return this.styleOrdersHistoryTable
                }}

            />

            <AgGridColumn
                field={"portfolioValue" as tRowKey}
                headerName={"Portfolio Value"}
                headerClass={"gridTable-header"}
                sortable={true}
                filter={true}
                resizable={false}
                cellStyle={(param:any) => {
                    if (param.data.type == '') {
                        return this.styleOrdersHistoryLastRow
                    }
                    return this.styleOrdersHistoryTable
                }}

            />
        </AgGridReact>
        <Navigation />
        </>
    }

}
